import React from 'react'
import { Link } from 'react-router-dom'

export default function MobileNavbar() {
    return (
        <div>
            <ul className="menu  bg-black w-full  text-white">
                {/* <li className="menu-title">Title</li> */}
                <Link to={'/'}>
                    <li><a>Home</a></li>
                </Link>
                <Link to={'/management'}>
                    <li><a>Team</a></li>
                </Link>
                <Link to={'/about'}>
                    <li><a>About Us</a></li>
                </Link>
                <Link to={'/contact'}>
                    <li><a>Conatact Us</a></li>
                </Link>
            </ul>
        </div>
    )
}
