import React from 'react'

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/material';

const steps = [
  {
    label: 'Import and manufacture',
    description: `TUB International Ltd. is a leading toy and electric gadget import company dedicated to providing high-quality products to both wholesale and retail customers. Our primary focus is on importing a wide range of toys and electric gadgets from various international manufacturers, ensuring that our inventory is diverse and exciting.
    Once we import our products, we distribute them through multiple channels, including local stores and our e-commerce website. We believe in offering convenience to our customers, allowing them to access our products through their preferred shopping method.`,
  },
  {
    label: 'Wholesale',
    description:
      'For our wholesale customers, we provide competitive pricing and attractive bulk purchase options. We understand the importance of maintaining a strong and mutually beneficial relationship with our wholesale partners, so we strive to offer them excellent customer service, prompt order fulfillment, and reliable product availability.',
  },
  {
    label: 'Retail locally and online',
    description: `In addition to our wholesale operations, we cater to individual customers through our retail division. Our physical stores provide a captivating shopping experience, allowing customers to explore and interact with our products before making a purchase. Furthermore, our user-friendly website offers an online platform for customers to conveniently browse, select, and order products from the comfort of their homes.`,
  },
  {
    label: 'Maintaining products quality and safety',
    description: `At TUB International Ltd., we are committed to maintaining high standards of quality and safety for all our products. We thoroughly vet the manufacturers we collaborate with to ensure that their products adhere to international safety regulations and industry standards. This dedication to quality ensures that our customers can have complete confidence in the products they purchase from us.`,
  },
  {
    label: 'Monitor market trends',
    description: `As a company, we constantly monitor market trends and strive to bring the latest and most innovative toys and electric gadgets to our customers. We understand the importance of staying ahead in the dynamic consumer electronics industry and continuously update our inventory to reflect the evolving demands and preferences of our customers.`,
  },
  {
    label: 'Customer satisfaction and after-sales support',
    description: `Moreover, we prioritize customer satisfaction and provide excellent after-sales support. Our knowledgeable and friendly customer service team is available to address any queries or concerns that our customers may have. We strive to exceed expectations by offering efficient product warranties, hassle-free returns, and timely assistance.`,
  },
  {
    label: 'Summary',
    description: `In summary, TUB International Ltd. is a reputable toy and electric gadget import company that is dedicated to providing high-quality products to both wholesale and retail customers. With our wide range of products, convenient distribution channels, and commitment to customer satisfaction, we aim to be a trusted and preferred supplier in the industry.`,
  },
];

export default function WhatWeDoPage() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  return (
    <div className=' my-16 bg-white'>
      <div className=' w-full flex flex-col'>
        <h1 className=' flex justify-center items-center text-center text-gray-900 font-bold text-3xl'>What We Do</h1>
        <div className=' w-full flex justify-center items-center text-center mt-2'>
          {/* <div className=' w-10 h-1 bg-green-700 '></div> */}
        </div>
      </div>
      <div className=' flex justify-center items-center text-center my-8 mx-8'>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper activeStep={activeStep} orientation="vertical" >
            {steps.map((step, index) => (
              <Step key={step.label} >
                <StepLabel sx={{
                  color: "#757e8d", //gray when disabled/inactive
                  "& .MuiStepLabel-label.Mui-active": {
                    color: "black", // color icon blue (when active)
                    fontSize: "16px",
                    fontWeight: "bold"

                  }
                }}
                  optional={
                    index === 3 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography sx={{ textAlign: "left" }}>{step.description}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button

                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          mt: 1, mr: 1, backgroundColor: "#ff6347", ":hover": {
                            bgcolor: "black",
                            color: "white"
                          }
                        }}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography sx={{
                color: "green", fontSize: "18px"
              }}>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      </div>

      {/* <div className=' mx-32 my-10 flex flex-row justify-between'>
             <div className=' flex flex-row '>
             
             <div className=' h-80 w-[450px] border-r-2 rounded-full border-gray-500 border-dashed justify-center items-center text-center '>
                    <h1 className=' '>yugefygwe</h1>
            </div>
             </div>
              <div className=' h-80 w-[450px] border-l-2 rounded-full border-gray-500 border-dashed'>

              </div>
        </div> */}

      {/* <div className=' flex flex-col space-y-52'>
                <div className=' h-14 w-14 bg-black rounded-full '>

                </div>
                <div className=' h-14 w-14 bg-black rounded-full '>

                </div>

              </div> */}
    </div>
  )
}
