import React from 'react'
import DesktopNavbar from '../navbar_component/DesktopNavbar'
import UpperNav from '../navbar_component/UpperNav'
import WhatWeDoPage from '../what_we_do/WhatWeDoPage'
import DesktopFooter from '../footer_component/DesktopFooter'
import WhyUsPage from '../why_us/WhyUsPage'
import GetToKnowUsPage from '../get_to_know_us_component/GetToKnowUsPage'
import CategoryPage from '../category_component/CategoryPage'
import ImageSliders from './ImageSliders'

export default function HomePage() {
  return (
    <div>
      <div className=' bg-white'>
        <UpperNav />
        <DesktopNavbar />
        <ImageSliders />
        <WhatWeDoPage />
        <CategoryPage />
        <GetToKnowUsPage />
        <WhyUsPage />

        <DesktopFooter />

      </div>
    </div>
  )
}
