import React, { useRef } from 'react'
import UpperNav from '../navbar_component/UpperNav'
import DesktopNavbar from '../navbar_component/DesktopNavbar'
import DesktopFooter from '../footer_component/DesktopFooter'

export default function AboutUsPage() {
    return (
        <div className=' bg-white'>
            <UpperNav />
            <DesktopNavbar />
            <div className=' my-16 flex justify-center items-center flex-col space-y-10'>
                <div className=' w-full flex flex-col'>
                    <h1 className=' flex justify-center items-center text-center text-gray-900 font-bold text-3xl'>We're Changing the</h1>
                    <h1 className=' flex justify-center items-center text-center text-gray-900 font-bold text-3xl'>Whole Game</h1>
                    <div className=' w-full flex justify-center items-center text-center mt-2'>
                        {/* <div className=' w-16 h-1 bg-green-700 mt-1 '></div> */}
                    </div>
                </div>
                <img src="/images/slider2.png" className=' w-3/4 h-96 shadow-lg p-4 border-[0.5px] border-gray-300 rounded-md ' alt="team" />
                <div className=' flex md:flex-row flex-col md:space-x-12 space-y-4 justify-center items-center '>
                    <div className=' flex flex-col items-start md:w-1/2 w-full  '>
                        <h1 className=' text-green-700 text-2xl font-bold mb-5 mx-16 md:mx-0 '>OUR STORY</h1>
                        <p className='mx-16 md:mx-0  font-medium text-[16px] text-black text-start'>Once upon a time in a bustling city, five friends named Towhidul Haqe, Ibrahim Omar, Ashraful Alam, Sohid Mia, and Shamim Hasan dreamt of starting their own toy and electric company. They had always been fascinated by innovative gadgets and toys, and they believed they could create something extraordinary together.

                            Towhidul, with his sharp business acumen, took charge of managing the company's finances and marketing strategies. Ibrahim, a brilliant Worker, was responsible for designing and developing the toys and electronic devices. Ashraful, a creative artist, used his skills to design eye-catching packaging and advertisements. Sohid, the master of logistics and operations, ensured that the production and distribution processes ran smoothly. And Shamim, a tech enthusiast, was in charge of researching new trends and technologies to incorporate into their products.

                            They pooled their resources, secured a small office space, and set up their workshop. With limited funds, they began their journey by creating small electronic toys and gadgets. The friends spent countless hours brainstorming, prototyping, and refining their ideas. Their hard work and dedication paid off when they finally launched their first product, a robotic dog that could perform tricks and respond to voice commands. The toy became an instant hit, and orders started pouring in.

                            As the company gained recognition, their vision expanded. They realized they had the potential to create more than just toys. Towhidul proposed the idea of designing eco-friendly electronic devices to promote sustainability. The friends embraced the concept and focused on developing energy-efficient products such as solar-powered chargers and LED lights.

                            Their commitment to quality and innovation quickly earned them a loyal customer base. People appreciated the company's emphasis on eco-friendly practices, and the word spread like wildfire. Local stores and online marketplaces began stocking their products, and the demand continued to grow.

                            With their success, the friends decided to expand their business. They hired talented individuals who shared their passion and values. Towhidul managed the team efficiently, ensuring everyone's skills were put to the best use. They invested in research and development, aiming to revolutionize the toy and electric industry with groundbreaking inventions.

                            As the company flourished, Towhidul, Ibrahim, Ashraful, Sohid, and Shamim never forgot their humble beginnings. They actively participated in community outreach programs, donating toys and electronic devices to underprivileged children. They also organized workshops to teach children about technology and its positive impact on society.

                            Years passed, and their company became a global brand, known for its innovative products and commitment to the environment. Towhidul, Ibrahim, Ashraful, Sohid, and Shamim had built an empire that not only brought joy to people's lives but also contributed to a greener and more sustainable future.

                            Their journey from four friends with a dream to successful entrepreneurs taught them the importance of teamwork, perseverance, and staying true to their principles. Together, they proved that with passion and dedication, dreams can become reality, and a small toy and electric company can change the world.</p>
                    </div>
                    <div className=' flex flex-col items-start md:w-96 w-full   space-y-2'>
                        <div className=' flex flex-col items-start md:w-96 w-full mx-8 md:mx-0'>
                            <h1 className=' font-bold text-lg text-black text-start mb-2' >01. Focus on eco-friendly and sustainable products</h1>
                            <p className='w-80 md:w-96 mx-8 md:mx-0  text-gray-600 text-sm text-start ml-6'>
                                Another key point is the company's emphasis on designing and developing eco-friendly electronic devices. Recognizing the potential to promote sustainability, the friends expanded their vision beyond toys and ventured into creating energy-efficient products such as solar-powered chargers and LED lights.
                            </p>
                        </div>
                        <div className=' flex flex-col items-start md:w-96 w-full mx-8 md:mx-0'>
                            <h1 className=' font-bold text-lg text-black text-start mb-2' >02. Impact on society and commitment to community</h1>
                            <p className=' text-gray-600 text-sm text-start w-80 md:w-96 mx-8 md:mx-0'>
                                The third key point is the friends' commitment to making a positive impact on society. They actively engaged in community outreach programs, donating toys and electronic devices to underprivileged children. Additionally, they organized workshops to educate children about technology and its potential to create a better future. Their dedication to social responsibility and giving back to the community became an integral part of their company's ethos.
                            </p>
                        </div>
                        <div className=' flex flex-col items-startmd:w-96 w-full mx-8 md:mx-0'>
                            <h1 className=' font-bold text-lg text-black text-start mb-2' >03. Formation of the dream team</h1>
                            <p className=' text-gray-600 text-sm text-start w-80 md:w-96 mx-8 md:mx-0'>
                                The first key point from the paragraph is the formation of a group of five friends who shared a common dream of starting a toy and electric company. Towhidul, Ibrahim, Ashraful, Sohid, and Shamim came together with their respective skills and expertise to pursue their passion for innovative gadgets and toys.
                            </p>
                        </div>
                    </div>
                </div>
                <div className=' flex md:flex-row md:space-x-12 flex-col p-6 justify-center items-center'>
                    <div className=' flex flex-col items-start md:w-1/2 w-full '>
                        <h1 className=' text-black font-bold text-xl mb-5'>Our Mission</h1>
                        <p className=' text-sm text-gray-600 text-start'>
                            At WeTub International, our mission is to provide high-quality, safe toys, and electric items that inspire imagination, promote learning, and bring joy to children and families worldwide. We are committed to upholding the highest standards of product safety, ensuring that every item we offer meets or exceeds the strictest global safety regulations.

                            We believe that play is a fundamental aspect of childhood development, fostering creativity, cognitive skills, and social interaction. Therefore, our products are carefully designed to engage and entertain children, while also encouraging their physical, emotional, and intellectual growth.

                            Central to our mission is a deep commitment to quality. We meticulously source materials from trusted suppliers, ensuring that our toys and electric items are durable, non-toxic, and environmentally friendly. Our rigorous quality control processes guarantee that each product undergoes thorough testing to meet the highest industry standards before reaching the hands of our customers.

                            We are dedicated to continuously innovating and staying at the forefront of technological advancements. Our electric items are designed to be both educational and safe, providing children with exciting opportunities to explore STEM concepts while prioritizing their well-being.

                            Furthermore, we recognize the importance of responsible business practices. We strive to minimize our environmental impact by embracing sustainable manufacturing processes, reducing waste, and promoting recycling initiatives. We also prioritize ethical sourcing and work closely with our partners to ensure fair labor practices throughout our supply chain.

                            At WeTub International, we value the trust placed in us by parents, caregivers, and communities worldwide. Through our commitment to delivering safe and high-quality toys and electric items, we aim to foster unforgettable experiences, lasting memories, and a world of endless possibilities for children everywhere.

                            Together, let's create moments of wonder, exploration, and growth through play!
                        </p>
                    </div>
                    <div>
                        <img src="/images/mission.jpg" alt="vission" className=' mt-8  h-[400px] md:w-80 w-full rounded-md shadow-md' />
                    </div>

                </div>
                <div className=' flex md:flex-row flex-col md:space-x-12 p-6 justify-center items-center'>
                    <div className=' flex flex-col items-start md:w-1/2 w-full space-y-2'>
                        <h1 className=' text-black font-bold text-xl mb-5'>Our Vision</h1>
                        <p className=' text-sm text-gray-600 text-start'>
                            That sounds like an ambitious and commendable vision! Establishing a toy factory in Bangladesh with the goal of reducing toy imports, producing high-quality toys locally, and exporting them worldwide can have several positive impacts.












                        </p>
                        <p className=' text-sm text-gray-600 text-start'>1. Economic Development: Building a toy factory in Bangladesh can contribute to the country's economic growth. By producing toys domestically, you can reduce the reliance on imports and stimulate local manufacturing. This can lead to the creation of job opportunities and increased income for people in Bangladesh.</p>
                        <p className=' text-sm text-gray-600 text-start'>2. Export Potential: Exporting toys manufactured in Bangladesh can generate foreign exchange earnings, which can contribute to the country's overall economy and help improve the balance of trade. It can also enhance Bangladesh's reputation as a reliable and competitive manufacturer of toys, attracting more international business opportunities.</p>
                        <p className=' text-sm text-gray-600 text-start'>3. Remittance and Foreign Investment: A successful toy factory can bring in foreign investment to Bangladesh, as well as generate remittances by exporting toys. This can further strengthen the country's economy and provide additional resources for development projects and social welfare programs.</p>
                        <p className=' text-sm text-gray-600 text-start'>4. Job Creation: By establishing a toy factory, you can provide employment opportunities for jobless individuals in Bangladesh. The manufacturing process, as well as other support functions such as marketing, logistics, and administration, can create jobs across various skill levels, benefiting the local workforce.</p>
                        <p className=' text-sm text-gray-600 text-start'>5. Skill Development: The establishment of a toy factory can contribute to the development of technical skills among the local workforce. It can provide training and employment opportunities for workers to enhance their expertise in areas such as toy design, manufacturing processes, quality control, and other related fields.</p>
                        <p className=' text-sm text-gray-600 text-start'>To achieve your vision successfully, it is important to consider factors such as market research, production efficiency, quality control, compliance with international standards and regulations, supply chain management, and effective marketing strategies. Additionally, fostering partnerships with local communities, educational institutions, and government bodies can further support the growth and sustainability of your toy factory.

                            Overall, your vision has the potential to make a positive impact on the economy of Bangladesh by reducing imports, creating employment opportunities, and contributing to the country's export sector.</p>
                    </div>
                    <div>
                        <img src="/images/vision.jpg" alt="vission" className=' mt-8 h-[420px] md:w-80 w-full rounded-md shadow-md' />
                    </div>

                </div>
            </div>
            <DesktopFooter />
        </div>
    )
}
