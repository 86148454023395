import React, { useRef } from 'react'
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

export default function ImageSliders() {
    return (
        <div className=' mb-16'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // pagination={{
                //   clickable: true,
                // }}
                //   navigation={true}

                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide><img
                    className="md:h-96 md:w-full h-52 w-full "
                    src="/images/slider1.png"
                    alt=""
                /></SwiperSlide>
                {/* <SwiperSlide> <img
                    className="md:h-96 md:w-full h-52 w-full shadow-md"
                    src="/images/cha.jpg"
                    alt=""
                /></SwiperSlide> */}
                <SwiperSlide><img
                    className="md:h-96  md:w-full h-52 w-full  shadow-md"
                    src="/images/slider2.png"
                    alt=""
                /></SwiperSlide>

            </Swiper>

        </div>
    )
}
