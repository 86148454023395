import React from 'react'
import { useRef } from 'react'
export default function GetToKnowUsPage() {
  return (
    <div className=' my-16 bg-white'>

      <div className=' w-full flex flex-col'>
        <h1 className=' flex justify-center items-center text-center text-gray-900 font-bold text-3xl'>Get To Know Us</h1>
        <div className=' w-full flex justify-center items-center text-center mt-2'>
          {/* <div className=' w-10 h-1 bg-green-700 '></div> */}
        </div>
      </div>

      <div className='flex justify-center items-center text-center md:mt-16 mt-8 mx-10'>
        <div className='p-8 shadow-md md:flex md:flex-row flex flex-col space-y-6 md:space-x-12 '>
          <img className=' w-[450px]' src="/images/logo.png" alt="banner" />
          <p className=' md:w-3/4 w-full text-sm text-gray-600 text-start'>
            Welcome to Tub International! We are an innovative company that specializes in importing, supplying, wholesaling, and retailing toys and electric items. Established in 2023, our mission is to provide children with high-quality, safe, and educational toys that contribute to their growth and development.

            At Tub International, we understand the importance of play in a child's life. We believe that toys are not just for entertainment; they are tools that stimulate creativity, imagination, and cognitive skills. With this in mind, we carefully curate our product selection to ensure that every toy we offer has educational value and is designed to enhance various aspects of a child's development.

            In addition to our import and distribution operations, we have an ambitious vision to establish our very own toy factory. This strategic move will allow us to have greater control over the production process, ensuring that our toys meet the highest standards of quality and safety. By manufacturing our toys, we aim to create a positive impact by promoting ethical and sustainable practices throughout the supply chain.

            Safety is a top priority for us. We adhere to stringent safety standards and regulations, ensuring that all our toys undergo thorough testing and comply with international safety guidelines. We are committed to providing parents and guardians with peace of mind, knowing that the toys we offer are safe for their children to play with.

            As a company, we are dedicated to fostering creativity and curiosity in children. We believe that learning should be an enjoyable experience, and our range of educational toys reflects this belief. From building blocks and puzzles to science kits and interactive learning devices, our products are designed to make learning engaging, interactive, and fun.

            Furthermore, at Tub International, we value sustainability and social responsibility. We actively seek partnerships with suppliers who share our commitment to environmental protection and ethical manufacturing practices. We strive to minimize our carbon footprint and contribute positively to the communities in which we operate.

            Our team consists of passionate individuals who are experts in their fields. We continuously research and explore emerging trends and advancements in the toy industry to ensure that we offer the latest and most innovative products to our customers.

            Whether you are a retailer, wholesaler, or parent, Tub International is your go-to destination for high-quality, safe, and educational toys. We are excited to embark on this journey with you and play a part in shaping a brighter future for children through our commitment to providing them with the best toys and educational experiences.
          </p>
        </div>
      </div>
      {/* <div className="card lg:card-side bg-base-100 shadow-xl">
  <figure><img src="/images/get.jpg" className=' w-80 h-80' alt="Album"/></figure>
  <div className="card-body">
    <h2 className="card-title">Get To Know!</h2>
    <p>
    Introducing "RetroGlam," the iconic fashion brand that transports you back to the vibrant and stylish era of the 1980s. With a fusion of bold colors, statement patterns, and edgy designs, RetroGlam embraces the essence of the decade, catering to those with an unapologetic flair for fashion. From neon-infused streetwear to glitzy evening ensembles, their collections exude an electrifying energy that captivates the modern fashionista. Embrace your inner '80s icon with RetroGlam and make a statement that transcends time. Get ready to unleash.

    </p>
    <div className="card-actions justify-end">
      <button className="btn btn-primary">Listen</button>
    </div>
  </div>
</div> */}

    </div>
  )
}
