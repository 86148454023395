import React from 'react'
import UpperNav from '../navbar_component/UpperNav'
import DesktopFooter from '../footer_component/DesktopFooter'
import DesktopNavbar from '../navbar_component/DesktopNavbar'

export default function TermPage() {
    return (
        <div className=' bg-white'>
            <UpperNav />
            <DesktopNavbar />
            <div className=' w-full h-screen flex justify-center items-center'>
                <h1 className=' text-green-600 text-3xl font-bold'>Coming Soon......</h1>
            </div>
            <DesktopFooter />

        </div>
    )
}
