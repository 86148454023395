import { useRef } from "react"
import React from 'react'

export default function CategoryPage() {
  return (
    <div className=" my-16 bg-white">
      <div className=' w-full flex flex-col'>
        <h1 className=' flex justify-center items-center text-center text-gray-900 font-bold text-3xl'>Our Catgories</h1>
        <div className=' w-full flex justify-center items-center text-center mt-2'>
          {/* <div className=' w-10 h-1 bg-green-700 '></div> */}
        </div>
      </div>
      <div className=" flex justify-center items-center text-center md:mt-16 mt-8">
        <div className=" flex flex-row md:space-x-10 space-x-3 ">
          {/* start cat    */}
          <div className="md:w-72 md:h-80 h-20 w-20 bg-white p-4 flex justify-center items-center shadow-lg image-full rounded-md">
            <figure><img src="/images/c1.jpg" alt="c1" className=" w-full h-full" /></figure>

          </div>
          {/* end cat */}
          {/* start cat    */}
          <div className="md:w-72 md:h-80 h-20 w-20 bg-white p-4 flex justify-center items-center shadow-lg image-full rounded-md">
            <figure><img src="/images/c2.jpg" alt="c2" /></figure>

          </div>
          {/* end cat */}
          {/* start cat    */}
          <div className="md:w-72 md:h-80 h-20 w-20 bg-white p-4 flex justify-center items-center shadow-lg image-full rounded-md">
            <figure><img src="/images/c3.jpg" alt="c3" /></figure>

          </div>
          {/* end cat */}
          {/* start cat    */}
          <div className="md:w-72 md:h-80 h-20 w-20 bg-white p-4 flex justify-center items-center shadow-lg image-full rounded-md">
            <figure><img src="/images/c4.jpg" alt="c4" /></figure>

          </div>
          {/* end cat */}
        </div>
      </div>
    </div>
  )
}
