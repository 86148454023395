import React from 'react';
import { makeStyles } from '@mui/material';

export default function WhyUsPage() {
  return (
    <div className=' my-16 bg-white'>
      <div className=' w-full flex flex-col'>
        <h1 className=' flex justify-center items-center text-center text-gray-900 font-bold text-3xl'>Why Us ?</h1>
        <div className=' w-full flex justify-center items-center text-center mt-2'>
          {/* <div className=' w-16 h-1 bg-green-700 mt-1 '></div> */}
        </div>
      </div>
      <div className=' grid md:grid-cols-4 grid-cols-2 gap-8 justify-center items-center text-center mt-16 mx-8'>
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Wide Range of Products</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB International Ltd. offers a diverse and extensive selection of toys and electric gadgets. From popular brands to unique and innovative products, customers can find a wide range of options to suit their needs and preferences.</p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>High-Quality Products</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. offers top-notch toys and gadgets, partnering with reputable manufacturers to guarantee quality. Customer satisfaction is paramount, and strict quality standards are upheld through careful supplier selection.</p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Competitive Pricing and Quality</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. offers competitive prices for wholesale and retail customers. By importing directly from manufacturers and optimizing the supply chain, they ensure affordability without compromising quality. Choose TUB Intl. Ltd. </p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Reliable Supply and Availability</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. is known for its reliable supply and product availability. Strong supplier relationships and efficient inventory management guarantee consistent stock of popular items. Say goodbye to delays and find products you need.</p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Convenient Shopping Experience</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. offers convenience with its physical store and user-friendly website. Detailed product descriptions, transparent pricing, and secure payments simplify online shopping. Find and buy your desired toys and gadgets effortlessly with TUB.</p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Excellent Customer Service</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. excels in customer service with a friendly and knowledgeable team. They provide assistance, recommendations, and after-sales support, ensuring positive experiences. Choose TUB for exceptional service that keeps customers coming back.</p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Reputation and Trust</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. has a trusted reputation, earned through commitment to quality, reliability, and customer satisfaction. Positive reviews and recommendations endorse them as a preferred choice for toy and gadget.</p>
        </div>

        {/* end column */}
        {/* start colum */}
        <div className=' flex flex-col items-center shadow-md p-6 rounded-md'>
          <div className=' h-20 w-20 rounded-full bg-teal-300 flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

          </div>
          <p className=' my-2 text-gray-600 text-sm font-bold'>Trustworthy Supplier</p>
          <p className='w-full text-justify text-xs text-gray-600 px-2'>TUB Intl. Ltd. is a trusted supplier, prioritizing quality, reliable supply, and exceptional customer service. Count on them for consistent delivery of high-quality products, fostering long-term relationships and peace of mind for buyers.</p>
        </div>

        {/* end column */}

      </div>

    </div>
  )
}
