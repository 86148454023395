import React from 'react'
import DesktopFooter from '../footer_component/DesktopFooter'
import UpperNav from '../navbar_component/UpperNav'
import DesktopNavbar from '../navbar_component/DesktopNavbar'

export default function PrivacyPolicyPage() {
    return (
        <div className=' bg-white'>
            <UpperNav />
            <DesktopNavbar />
            <div className=' my-16 mx-24 flex flex-col items-start space-y-4'>
                <p className=' text-start text-sm font-medium text-gray-600'>Thank you for visiting our website. This Privacy Policy describes how we handle and protect your personal information when you access and use our website. We understand the importance of privacy and are committed to maintaining the confidentiality of your information. Please read this Privacy Policy carefully to understand our practices regarding your personal information.</p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    1. Information Collection
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    We want to emphasize that we do not collect any personal information from our website users. We do not use cookies or any other tracking technologies to gather data about your browsing activities on our website.
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    2. Information Usage
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    Since we do not collect any personal information, we do not use or share any such information for any purpose. We do not analyze or monitor user behavior on our website, and we do not engage in any targeted advertising or profiling activities.
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    3. Third-Party Links
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    Our website may contain links to third-party websites for your convenience and information. However, we are not responsible for the privacy practices or the content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    4. Data Security
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    We take reasonable steps to protect the information you provide to us. However, since we do not collect any personal information, we do not store, transmit, or process any data that requires security measures. Therefore, we cannot guarantee the absolute security of any information transmitted or accessed through our website.
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    5. Children's Privacy
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    Our website is not intended for children under the age of 13. We do not knowingly collect any personal information from children. If you believe we may have collected information from a child under the age of 13, please contact us, and we will promptly delete that information.
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    5. Changes to this Privacy Policy
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    We reserve the right to modify or update this Privacy Policy at any time. We will notify you of any significant changes by posting the updated Privacy Policy on our website with a revised effective date. We encourage you to review this Privacy Policy periodically for any updates.
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    Contact Us
                </p>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    If you have any questions or concerns about this Privacy Policy, please contact us at example@exmple.com.

                    By using our website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.

                    Please note that this Privacy Policy solely applies to our website and does not cover any offline activities or websites linked to from our website.
                </p>
            </div>
            <DesktopFooter />
        </div>
    )
}
