import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './components/home_component/HomePage';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ManagementPage from './components/team_component/ManagementPage';
import AboutUsPage from './components/about_us_component/AboutUsPage';
import PrivacyPolicyPage from './components/legal/PrivacyPolicyPage';
import CookiePolicyPage from './components/legal/CookiePolicyPage';
import ContactUsPage from './components/contact_us_component/ContactUsPage';
import TermPage from './components/legal/TermPage';
import HomePageNew from './components/home_component/HomePageNew';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePageNew />} />
          <Route path="/management" element={<ManagementPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/cookie" element={<CookiePolicyPage />} />
          <Route path="/term" element={<TermPage />} />
          <Route path="/new-home" element={<HomePage />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
