import React from 'react'
import UpperNav from '../navbar_component/UpperNav'
import DesktopNavbar from '../navbar_component/DesktopNavbar'
import DesktopFooter from '../footer_component/DesktopFooter'

export default function CookiePolicyPage() {
    return (
        <div className=' bg-white'>
            <UpperNav />
            <DesktopNavbar />
            <div className=' my-16 mx-24 flex flex-col items-start space-y-4'>
                <p className=' text-start text-sm font-medium text-gray-600'>
                    Thank you for visiting our website. This Cookie Policy explains how we use cookies on our website. Please read this policy carefully to understand our practices regarding cookies.
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    1. What are Cookies?
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you visit a website. They are widely used to make websites work more efficiently and provide a better browsing experience. Cookies can be temporary (session cookies) or permanent (persistent cookies).
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    2. Our Use of Cookies
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    We want to emphasize that we do not use any cookies on our website. We do not collect or store any personal information through cookies, and we do not engage in any tracking or profiling activities.
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    3. Third-Party Cookies
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    Our website may contain links to third-party websites or resources. These third-party websites may use their own cookies to collect information about your browsing activities. We have no control over these cookies and are not responsible for the privacy practices or content of these third-party websites. We encourage you to review the privacy policies of any third-party websites you visit.
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    4. Managing Cookies
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    As we do not use any cookies on our website, there is no need for you to manage or disable them. However, please note that if you visit third-party websites through links on our website, you may need to manage cookies on those websites according to their respective cookie policies.
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    5. Changes to this Cookie Policy
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    We reserve the right to modify or update this Cookie Policy at any time. We will notify you of any significant changes by posting the updated Cookie Policy on our website with a revised effective date. We encourage you to review this Cookie Policy periodically for any updates.
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    Contact Us
                </p>
                <p className='text-start text-sm font-medium text-gray-600'>
                    If you have any questions or concerns about this Cookie Policy, please contact us at example@example.com.

                    By using our website, you acknowledge that you have read and understood this Cookie Policy and agree to its terms and conditions.

                    Please note that this Cookie Policy solely applies to our website and does not cover any offline activities or websites linked to from our website.
                </p>
            </div>
            <DesktopFooter />
        </div>
    )
}
