import React from 'react'
import UpperNav from '../navbar_component/UpperNav'
import DesktopNavbar from '../navbar_component/DesktopNavbar'
import DesktopFooter from '../footer_component/DesktopFooter'

export default function ContactUsPage() {
    return (
        <div className=' bg-white'>
            <UpperNav />
            <DesktopNavbar />
            <div className=' w-full  flex flex-col justify-center items-center'>
                <div className=' flex flex-col w-1/2 items-start space-y-4 mt-8  md:ml-28 mx-4'>
                    <h1 className=' text-black font-bold text-3xl md:mt-16 mt-8'>Contact Us</h1>
                    <div className=' flex flex-row space-x-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:w-6 md:h-6 w-10 h-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                        <p className=' font-semibold text-lg'>+880-222-444-1105 </p>
                    </div>
                    <div className=' flex flex-row space-x-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:w-6 md:h-6 w-10 h-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>

                        <p className=' font-semibold text-lg'>Chowrangi Super Market, Savar, Dhaka-1340 </p>
                    </div>
                    <div className=' flex flex-row space-x-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>


                        <p className=' font-semibold text-lg'>info@tubintbd.com</p>
                    </div>

                </div>
                <div className=' w-full  my-16 flex justify-center items-center flex-col'>
                    <img src="/images/tub.png" alt="tub" className=' w-48 h-48' />
                    <h1 className=' text-3xl  text-green-400 font-bold my-4'>TUB International Limited</h1>
                    <div className=' mx-8 md:w-full w-96 flex flex-row justify-center space-x-2 '>
                        <img src="/images/bta.jpg" alt="bit" className=' w-10 h-8 md:mt-0 mt-3 ' />
                        <p className=' font-medium  text-lg'>Memeber of Bangladesh Toy Merchants, Manufacturer and Importers Association</p>
                    </div>
                </div>
            </div>
            <DesktopFooter />
        </div>
    )
}
