import React, { useState } from 'react'
import UpperNav from '../navbar_component/UpperNav'
import DesktopNavbar from '../navbar_component/DesktopNavbar'
import ImageSliders from './ImageSliders'
import DesktopFooter from '../footer_component/DesktopFooter'

export default function HomePageNew() {
    const [showFullContent, setShowFullContent] = useState(false);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };
    return (
        <div>
            <div className=' bg-white'>
                <UpperNav />
                <DesktopNavbar />
                <ImageSliders />



                <div className=' w-full md:px-20 px-8 flex flex-col space-y-4 md:flex-row md:space-x-10 md:items-center'>
                    <div className=' flex-1 flex flex-col items-start space-y-3'>
                        <p className=' text-xs font-semibold text-gray-500 text-start'>Quality products and customer satisfaction is our main priority.</p>
                        <p className=' text-2xl font-bold text-black text-start'>Our quality product allows you to offer the lastest toys to your possible customer!</p>
                    </div>
                    <div className=' flex-1 flex flex-col items-start space-y-3'>
                        <p className=' text-xs font-semibold text-gray-500 text-start'>
                            Welcome to Tub International! We are an innovative company that specializes in importing, supplying, wholesaling, and retailing toys and electric items. Established in 2023, our mission is to provide children with high-quality, safe, and educational toys that contribute to their growth and development.
                        </p>
                        <div className=' w-full flex flex-col items-center space-y-2'>
                            <div className='w-full flex flex-row items-center justify-between'>
                                <div className='w-64 flex flex-row items-center space-x-2'>
                                    <div className='w-6 h-6 border-[1px] border-green-500 rounded-full flex justify-center items-center p-1'>
                                        <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                    </div>
                                    <p className='  text-sm text-black font-bold text-start'>Wide Range of Products</p>
                                </div>
                                <div className='w-64 flex flex-row items-center space-x-2'>
                                    <div className='w-6 h-6 border-[1px] border-green-500 rounded-full flex justify-center items-center p-1'>
                                        <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                    </div>
                                    <p className=' text-sm text-black font-bold text-start'>High-Quality Products</p>
                                </div>
                            </div>
                            <div className='w-full flex flex-row items-center justify-between'>
                                <div className='w-64 flex flex-row items-center space-x-2'>
                                    <div className='w-6 h-6 border-[1px] border-green-500 rounded-full flex justify-center items-center p-1'>
                                        <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                    </div>
                                    <p className='  text-sm text-black font-bold text-start'>Competitive Pricing and Quality</p>
                                </div>
                                <div className='w-64 flex flex-row items-center space-x-2'>
                                    <div className='w-6 h-6 border-[1px] border-green-500 rounded-full flex justify-center items-center p-1'>
                                        <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                    </div>
                                    <p className=' text-sm text-black font-bold text-start'>Reliable Supply and Availability</p>
                                </div>
                            </div>
                            <div className='w-full flex flex-row items-center justify-between'>
                                <div className='w-64 flex flex-row items-center space-x-2'>
                                    <div className='w-6 h-6 border-[1px] border-green-500 rounded-full flex justify-center items-center p-1'>
                                        <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                    </div>
                                    <p className='  text-sm text-black font-bold text-start'>Convenient Shopping Experience</p>
                                </div>
                                <div className='w-64 flex flex-row items-center space-x-2'>
                                    <div className='w-6 h-6 border-[1px] border-green-500 rounded-full flex justify-center items-center p-1'>
                                        <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                    </div>
                                    <p className=' text-sm text-black font-bold text-start'>Excellent Customer Service</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='h-12'></div>

                {/* what we do */}
                <div className=' grid md:grid-cols-4 grid-cols-2 gap-3 md:gap-8 md:px-20 px-6'>
                    <div className=' md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold text-start'>Import and manufacture</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            TUB International Ltd. is a leading toy and electric gadget import company dedicated to providing high-quality products to both wholesale and retail customers. Our primary focus is on importing a wide range of toys and electric gadgets from various international manufacturers, ensuring that our inventory is diverse and exciting. Once we import our products, we distribute them through multiple channels, including local stores and our e-commerce website.
                        </p>

                    </div>
                    <div className='  md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold'>Wholesale</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            For our wholesale customers, we provide competitive pricing and attractive bulk purchase options. We understand the importance of maintaining a strong and mutually beneficial relationship with our wholesale partners, so we strive to offer them excellent customer service, prompt order fulfillment, and reliable product availability.
                        </p>

                    </div>
                    <div className='  md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold'>Retail locally and online</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            In addition to our wholesale operations, we cater to individual customers through our retail division. Our physical stores provide a captivating shopping experience, allowing customers to explore and interact with our products before making a purchase. Furthermore, our user-friendly website offers an online platform for customers to conveniently browse, select, and order products from the comfort of their homes.
                        </p>

                    </div>
                    <div className='  md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold text-start'>Maintaining products quality and safety</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            At TUB International Ltd., we are committed to maintaining high standards of quality and safety for all our products. We thoroughly vet the manufacturers we collaborate with to ensure that their products adhere to international safety regulations and industry standards. This dedication to quality ensures that our customers can have complete confidence in the products they purchase from us.
                        </p>

                    </div>
                    <div className=' md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold text-start'>Monitor market trends</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            As a company, we constantly monitor market trends and strive to bring the latest and most innovative toys and electric gadgets to our customers. We understand the importance of staying ahead in the dynamic consumer electronics industry and continuously update our inventory to reflect the evolving demands and preferences of our customers.
                        </p>

                    </div>
                    <div className='  md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold text-start'>Customer satisfaction and after-sales support</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            Moreover, we prioritize customer satisfaction and provide excellent after-sales support. Our knowledgeable and friendly customer service team is available to address any queries or concerns that our customers may have. We strive to exceed expectations by offering efficient product warranties, hassle-free returns, and timely assistance.
                        </p>

                    </div>
                    <div className='  md:w-64 w-40 p-4 shadow-xl bg-white flex flex-col items-start'>
                        <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        <div className='h-1'></div>
                        <p className='text-[18px] text-black font-semibold text-start'>Summary</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-xs text-black text-justify `}>
                            In summary, TUB International Ltd. is a reputable toy and electric gadget import company that is dedicated to providing high-quality products to both wholesale and retail customers. With our wide range of products, convenient distribution channels, and commitment to customer satisfaction, we aim to be a trusted and preferred supplier in the industry.
                        </p>

                    </div>
                    <div className='  md:w-64 w-40 p-4 shadow-xl bg-green-400 flex flex-col items-start'>
                        <p className=' text-3xl text-white font-semibold text-start'>Help Your Bussiness Win!</p>

                        <div className='h-4'></div>
                        <p className={` w-full  text-sm font-medium text-white text-justify `}>
                            Tub's smart and productive team will support you to expand your bussiness with their quality product and constant customer support. Be our partner today, it will be a win win situation.
                        </p>

                    </div>
                </div>
                <div className='h-12'></div>
                {/* success stories */}
                <div className='h-12'></div>
                <div className=' w-full h-10 flex justify-center items-center'>
                    <p className=' text-black text-3xl font-bold'>Success Stories</p>
                </div>
                <div className='h-10'></div>
                <div className=' grid md:grid-cols-3 grid-cols-2 gap-3 md:gap-8 md:px-20 px-6'>
                    <div className='  md:w-96 w-60 md:px-16 md:py-8 p-4 shadow-xl bg-green-100 flex flex-col items-center space-y-2'>
                        <div className=' p-4 shadow-xl bg-white'>
                            <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        </div>
                        <div className='h-1'></div>
                        <p className='text-[20px] text-black font-bold'>Seamless Collaboration Leads to Blockbuster Launch</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-sm font-semibold text-black text-justify `}>

                            Teaming up with PlayWorld Innovations, Tub International Ltd crafted 'ConstructoBlox.' This innovative line of interactive building blocks seamlessly combined education with entertainment. The product was a smash hit, earning praise from parents, educators, and industry experts. This success solidified Tub International Ltd's position as a leader in educational toys.
                        </p>

                    </div>
                    <div className='  md:w-96 w-60 md:px-16 md:py-8 p-4 shadow-xl bg-green-100 flex flex-col items-center space-y-2'>
                        <div className=' p-4 shadow-xl bg-white'>
                            <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        </div>
                        <div className='h-1'></div>
                        <p className='text-[20px] text-black font-bold'>Sustainability Takes Center Stage with GreenPlay Initiative</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-sm font-semibold text-black text-justify `}>

                            Partnering with GreenTech Innovations, Tub International Ltd launched the GreenPlay line. By using recycled and biodegradable materials, they revolutionized sustainable toy manufacturing. The 'EcoAdventures Playset' became a favorite among eco-conscious consumers, setting a new standard in responsible toy production.
                        </p>

                    </div>
                    <div className='  md:w-96 w-60 md:px-16 md:py-8 p-4 shadow-xl bg-green-100 flex flex-col items-center space-y-2'>
                        <div className=' p-4 shadow-xl bg-white'>
                            <img src="/images/import.png" alt="import" className='h-10 w-10' />
                        </div>
                        <div className='h-1'></div>
                        <p className='text-[20px] text-black font-bold'>WhizKid's Playmate: The Imaginarium Board</p>
                        <div className='h-2'></div>
                        <p className={` w-full  text-sm font-semibold text-black text-justify `}>

                            In partnership with PlayTech Innovations, Tub International Ltd unveiled the 'Imaginarium Board,' a breakthrough in creative play. This innovative product sparked imaginations by seamlessly merging physical play with imaginative storytelling. Parents and educators lauded its ability to inspire learning through play, solidifying Tub International Ltd as a pioneer in redefining traditional play experiences.
                        </p>

                    </div>
                </div>
                {/* success stories */}

                <div className='h-12'></div>

                {/* what we do */}

                {/* bani */}
                <div className=' py-8 w-full bg-gray-50 px-32'>
                    <div className='h-4'></div>
                    <p className=' w-full h-10 flex justify-center items-center text-green-600 text-lg font-semibold'>
                        In Your Faith....
                    </p>
                    <div className='h-4'></div>
                    <p className=' text-3xl font-bold text-gray-800 '>
                        If you are looking for rewarding bussiness partner and chance to make an impact. you have come to the right place. We will transform your bussiness with our quality product and support.
                    </p>
                    <div className='h-4'></div>
                    <div className=' w-full h-24 flex justify-center items-center'>
                        <img src="/images/chairman.jpg" alt="chairman" className=' w-20 h-20 rounded-full' />
                    </div>
                    <p className=' text-black text-lg font-bold w-full h-10 flex justify-center items-center'>Md. Towhidul Haqe Towhid</p>
                    <p className=' text-black text-lg font-bold w-full h-10 flex justify-center items-center' >Chairman, TUB International Ltd.</p>

                </div>
                {/* bani */}
                <div className='h-12'></div>


                <DesktopFooter />

            </div>
        </div>
    )
}
