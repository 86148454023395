import React, { useRef } from 'react'
import UpperNav from '../navbar_component/UpperNav'
import DesktopNavbar from '../navbar_component/DesktopNavbar'
import DesktopFooter from '../footer_component/DesktopFooter'


export default function ManagementPage() {
  return (
    <div className=' bg-white'>
      <UpperNav />
      <DesktopNavbar />
      <div className='my-16 mx-8 bg-white'>
        <div className=' flex flex-col items-start space-y-10'>


          <div className=' flex    md:flex-row flex-col w-full md:mx-0 mx-4 md:space-x-10 space-y-8 items-start'>
            <div className=" md:w-1/2 w-full  shadow-md p-8 rounded-md">
              <div className=' flex flex-col float-left shadow-md rounded-sm p-4 m-4 '>
                <img className="md:float-left float-none p-4 h-64 w-64" src="/images/chairman.jpg" alt="chairman" />
                <p className=' font-semibold text-black'> Md. Towhidul Haqe Towhid</p>
                <p className=' font-semibold text-black'> Chairman, TUB International Ltd.</p>
              </div>
              <p className="text-justify">
                Great pleasure and enthusiasm that I address me today as the Chairman of our newly established company, Tub International. As we embark on this remarkable journey in the year 2023, I am filled with anticipation for the endless possibilities and opportunities that lie ahead of us.

                At Tub International, we pride ourselves on being an import, wholesale, and retail company specializing in the distribution of toys and electric items. Our vision is not only to cater to the demands of children and consumers worldwide but also to make a lasting impact on their lives through safe, educational toys.

                In line with our commitment to excellence, we aspire to take our operations to the next level by establishing our very own toy factory. By doing so, we will have greater control over the manufacturing process, ensuring that our products meet the highest standards of safety, quality, and innovation.

                One of our primary objectives is to provide children with toys that not only entertain but also foster their development and enhance their learning experiences. We believe that every child deserves access to toys that promote creativity, critical thinking, problem-solving, and imagination. By offering a diverse range of educational toys, we aim to empower children and contribute to their overall growth and well-being.

                Furthermore, as a responsible and socially conscious company, we are dedicated to promoting sustainability in our operations. Our toy factory will prioritize environmentally friendly practices, such as utilizing recycled materials, reducing waste, and minimizing our carbon footprint. We believe in leaving a positive legacy for future generations and safeguarding the world in which our children will grow and play.

                As the Chairman of Tub International, I am committed to leading our team with integrity, transparency, and a shared sense of purpose. Together, we will forge strong partnerships with suppliers, retailers, and stakeholders, ensuring mutual growth and success. Our dedication to customer satisfaction will remain at the heart of all our endeavors, as we strive to exceed expectations and establish ourselves as a trusted brand in the global toy industry.

                I extend my heartfelt gratitude to each and every member of the Tub International family for their unwavering support and dedication. Our journey has just begun, and I am confident that together, we will build a prosperous future and bring joy to countless children around the world.

                Thank you.




              </p>
            </div>
            {/* vice chairma hobe and ager tar justify center bad jabe */}
            <div className=" md:w-1/2 w-full shadow-md p-8 rounded-md">
              <div className=' flex flex-col float-left shadow-md rounded-sm p-4 m-4 '>
                <img className="md:float-left float-none p-4 h-64 w-64" src="/images/vice.jpg" alt="vice-chairman" />
                <p className=' font-semibold text-black'> MD Shoid Mia</p>
                <p className=' font-semibold text-black'> Vice-Chairman, TUB International Ltd.</p>
              </div>
              <p className="text-justify">
                Dear Esteemed Customers,

                Greetings! As the Vice-Chairman of TUB International Limited, I am delighted to reach out to you today and share our company's mission and vision, which reflect our deep commitment to delivering safe, educational, and high-quality toys.

                At TUB International, our mission is to import the finest toys that meet stringent safety standards while fostering educational growth in children. We prioritize the well-being and development of young minds, ensuring that our product range comprises toys that are both engaging and beneficial for their learning journey.

                In line with our vision, I am excited to announce that we are in the process of establishing a state-of-the-art toy factory. This strategic initiative aims to reduce toy imports and bolster the economy of Bangladesh. By producing toys locally, we intend to create numerous job opportunities for our fellow citizens and contribute to the overall growth and prosperity of our nation.

                Moreover, our aspirations extend beyond national borders. We aim to export our high-quality toys worldwide, sharing the joy and educational benefits they bring with children across the globe. By becoming a significant player in the international toy market, we hope to showcase the creativity and expertise of Bangladesh while uplifting our nation's economy on a global scale.

                We value your trust in TUB International Limited and appreciate your continued support as we embark on this transformative journey. Your patronage has been instrumental in our success, and we are committed to consistently exceeding your expectations with our product offerings.

                Thank you for being an integral part of our journey. Together, let us empower children, ignite their imaginations, and make a meaningful contribution to the growth of Bangladesh's economy.

                Warm regards,



                TUB International Limited




              </p>
            </div>


          </div>
          <div className='md:overflow-x-auto flex md:flex-row flex-col w-full md:mx-0 mx-4 md:space-x-10 space-y-8 items-start'>
            <div className=" md:w-1/2 w-full shadow-md p-8 rounded-md">
              <div className=' flex flex-col float-left shadow-md rounded-sm p-4 m-4 '>
                <img className="md:float-left float-none p-4 h-64 w-64" src="/images/md.jpg" alt="chairman" />
                <p className=' font-semibold text-black'> Md. Ibrahim Omar</p>
                <p className=' font-semibold text-black'> Managing Director, Tub International</p>
              </div>
              <p className="text-justify">
                Dear valued visitors and stakeholders,

                It is with great pleasure and excitement that I address you today as the Managing Director of our esteemed company. As we embark on this incredible journey in the world of international trade, I am honored to introduce our organization and share our vision for the future.

                At Tub International, our primary focus is to import, supply, wholesale, and retail a wide range of toys and electric items. We recognize the profound impact that toys have on children's development, and we are committed to providing safe, educational toys that bring joy and inspiration to young minds.

                Since our company's inception in 2023, we have strived to establish ourselves as a leading player in the industry. Our unwavering dedication to quality and excellence has garnered the trust and support of our valued customers, suppliers, and partners. We are immensely grateful for their ongoing confidence and belief in our capabilities.

                As we move forward, we are guided by a clear and ambitious vision: to establish our very own toy factory. This factory will serve as a testament to our commitment to innovation, creativity, and ensuring the utmost safety standards for children's toys. With our in-house production capabilities, we will have greater control over the manufacturing process, enabling us to deliver exceptional products that meet the highest quality benchmarks.

                Furthermore, our dedication to educational toys remains at the forefront of our operations. We believe that toys have the power to inspire and facilitate learning, and we aim to develop a diverse range of educational toys that engage young minds and foster their cognitive, social, and emotional growth.

                We are acutely aware of our responsibility towards our customers and society as a whole. In line with our commitment to corporate social responsibility, we strive to minimize our environmental impact through sustainable practices and ethical sourcing. We also actively support local communities and initiatives that promote education and the well-being of children.

                As we forge ahead, I extend my heartfelt gratitude to our exceptional team of professionals. Their expertise, passion, and relentless pursuit of excellence are the driving forces behind our success. Together, we are determined to surpass expectations, drive innovation, and create a lasting legacy in the toy industry.

                In conclusion, I invite you all to join us on this remarkable journey. Together, we can make a difference in the lives of children worldwide by providing them with safe, educational toys that spark their imagination and nurture their potential.

                Thank you for your unwavering support, and I look forward to a future filled with growth, success, and positive impact.

                Sincerely,








              </p>
            </div>
            <div className=" md:w-1/2 w-full shadow-md p-8 rounded-md">
              <div className=' flex flex-col float-left shadow-md rounded-sm p-4 m-4 '>
                <img className="md:float-left float-none p-4 h-64 w-64" src="/images/cfo.jpg" alt="ceo" />
                <p className=' font-semibold text-black'> Asraful Alam</p>
                <p className=' font-semibold text-black'> CEO, Tub International.</p>
              </div>
              <p className="text-justify">
                Valued Customers,

                I am thrilled to address you as the CEO of Tub International, a company committed to bringing joy, education, and innovation into the lives of children around the world. It is with great excitement that I share our vision and plans for the future.

                As we embark on this journey in 2023, our primary focus remains on importing, supplying, wholesaling, and retailing toys and electric items of the highest quality. We understand the significance of play in a child's development, and we strive to provide them with products that are not only entertaining but also safe and educational.

                At Tub International, we recognize the responsibility we have to nurture young minds and inspire their imagination. That is why we are dedicated to offering a wide range of toys and electric items that stimulate creativity, encourage problem-solving, and foster cognitive development. Whether it's building blocks, science kits, puzzles, or interactive learning devices, we ensure that each product meets the highest standards of safety and quality.

                Looking ahead, we have an ambitious plan to establish our very own toy factory. This endeavor will allow us to have complete control over the manufacturing process, enabling us to create toys that are tailor-made for the needs and desires of children. We envision a state-of-the-art facility that combines cutting-edge technology with eco-friendly practices, ensuring that our toys not only bring joy but also contribute to a sustainable future.

                The foundation of our success lies in our unwavering commitment to our customers. We believe in building strong relationships based on trust and reliability. Our team works tirelessly to source the finest toys and electric items from across the globe, bringing you the best selection available. We continuously strive to exceed your expectations by offering competitive pricing, prompt delivery, and exceptional customer service.

                In addition to our business objectives, Tub International believes in giving back to society. We are dedicated to making a positive impact on the lives of children, especially those in need. Through partnerships with charitable organizations and community initiatives, we aim to bring the power of play and education to underserved communities, ensuring that every child has access to enriching toys and learning experiences.

                I am incredibly proud of what we have achieved so far, and I am even more excited about the future of Tub International. Together with our passionate team, loyal customers, and esteemed partners, we will continue to make a difference in the lives of children worldwide.

                Thank you for joining us on this exciting journey. We look forward to serving you and bringing smiles to the faces of children everywhere.








              </p>
            </div>
            <div className=" md:w-1/2 w-full shadow-md p-8 rounded-md">
              <div className=' flex flex-col float-left shadow-md rounded-sm p-4 m-4 '>
                <img className="md:float-left float-none p-4 h-64 w-64" src="/images/cto.jpg" alt="cto" />
                <p className=' font-semibold text-black'> Md. Shamim Hasan</p>
                <p className=' font-semibold text-black'> CTO, Tub International.</p>
              </div>
              <p className="text-justify">
                valued visitors,

                I am thrilled to extend a warm welcome to all of you on behalf of Tub International, a company that embarked on a journey to bring joy and inspiration into the lives of children through toys and educational items. It is with great pride and enthusiasm that I introduce our company and share our vision for the future.

                At Tub International, we believe that every child deserves access to safe, high-quality toys that not only entertain but also foster creativity, imagination, and learning. Our commitment to this belief is what drives us to import and supply a wide range of toys and electric items, ensuring that children all over the world have access to innovative, engaging, and educational products.

                Founded in 2023, Tub International is a relatively young company, but we are fueled by a passion to make a positive impact on the lives of children. We understand the power of play in shaping young minds and helping them develop essential skills and abilities. As parents, caregivers, and educators, it is our responsibility to provide children with toys that enhance their cognitive, emotional, and physical growth.

                In line with our vision, we are excited to announce our plans to establish a state-of-the-art toy factory. This strategic move will enable us to have greater control over the manufacturing process, ensuring the highest standards of safety, quality, and ethical practices. Our factory will also enable us to design and produce our own range of toys that align with our values and meet the evolving needs of children in the 21st century.

                We firmly believe that education and play go hand in hand. That's why we are dedicated to curating a collection of toys and educational items that inspire curiosity, foster learning, and promote holistic development. From puzzles and building blocks to science kits and interactive gadgets, our diverse range of products aims to ignite the imaginations of children and encourage them to explore, discover, and grow.

                But our commitment extends beyond simply offering great products. We strive to provide exceptional customer service, ensuring that your experience with Tub International is nothing short of excellent. We value your trust and are dedicated to building long-lasting relationships with our customers, suppliers, and partners.

                As we embark on this exciting journey, we invite you to join us in shaping a brighter future for children everywhere. Together, let's create a world where play and education harmonize, where children are inspired to dream, learn, and thrive.

                Thank you for visiting Tub International, and we look forward to serving you and your loved ones with our exceptional range of toys and educational items.




                Director, Tub International







              </p>
            </div>

          </div>



        </div>
      </div>
      <DesktopFooter />
    </div>
  )
}
