import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileNavbar from './MobileNavbar'

export default function DesktopNavbar() {
  const [menu, setMenu] = useState(false);
  return (
    <div>
      <div className="block lg:hidden" >
        <div className=' w-full  items-start flex flex-col'>
          <div className='w-full h-10 bg-black pl-8 flex justify-start items-start'>
            <button onClick={() => { setMenu(!menu) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

            </button>
          </div>
          {
            menu ?
              <div className=' w-full bg-black pl-2'>
                <MobileNavbar />
              </div> : null
          }
        </div>
        {/* <MobileNavbar /> */}
      </div>
      {/* #272727 */}
      <div className="hidden lg:block">
        <div className="navbar  bg-black">
          <div className="navbar-start">

          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <Link to="/">
                <li><a className=' text-white'>Home</a></li>
              </Link>
              <div className=' w-10'></div>
              <Link to="/management">
                <li><a className=' text-white'>Team</a></li>
              </Link>
              <div className=' w-10'></div>
              <Link to="/about">
                <li><a className=' text-white'>About Us</a></li>
              </Link>
              <div className=' w-10'></div>
              <Link to="/contact">
                <li><a className=' text-white'>Contact Us</a></li>
              </Link>

            </ul>
          </div>
          <div className="navbar-end">

          </div>
        </div>
      </div>
    </div>
    //     <div className="navbar  bg-black">

    //   <div className="navbar-center">
    // <ul className="menu menu-horizontal px-16">
    //   <li><a className=' text-sm font-medium mx-4'>About Us</a></li>
    // <li>
    //   <details>
    //     <summary className=' text-sm font-medium'>
    //       Team
    //     </summary>
    //     <ul className="p-2 bg-base-100">
    //       <li><a>Management</a></li>
    //       <li><a>Directors</a></li>
    //       <li><a>Marketing</a></li>
    //       <li><a>Sales</a></li>
    //     </ul>
    //   </details>
    // </li>
    //     </ul>
    //   </div>
    // </div>
  )
}
